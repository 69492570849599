// myAssessmentsList
.myAssessmentsList {
    .AP_Img {
      width: 220px;
      height: 100%;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    .publishDetails {
      .p-text {
  
        // font-size: 12px;
        .nt {
          color: #737373;
        }
      }
    }
  
    .listItem {
      background: #FFFFFF;
      box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
  
      .myAssessmentLeftCon {
        .subtitle-text {
          font-weight: bold;
        }
  
        h6.subtitle-text {
          color: $primary_color;
          font-weight: bold;
          font-size: 1.2rem;
        }
  
        .p-text {
          @include text_ellipsis(3);
          font-size: .9rem;
          max-width: 80%;
        }
      }
  
      .myAssessmentDates {
        font-size: 14px;
        .editAssessmentAction{
          $circle_px: 45px;
          width: $circle_px;
          height: $circle_px;
          background-color: $primary_color;
          border-radius: 50%;
          @include flex_center;
          border: none;
          svg{
            path{
              fill: $color_white;
            }
          }
        }
      }
    }
  }

  