.assessmentWPBreadcrumbs {
  button {
    border: none;
    background-color: transparent;
    font-weight: $fontLight;
  }

  .MuiBreadcrumbs-separator {
    color: $primary_color;
    font-weight: $fontMedium;

    svg {
      width: 8px;
      height: auto;

      path {
        fill: $primary_color;
      }
    }
  }

  div {
    color: $primary_color;
    font-weight: $fontMedium;
  }
}
.assessmentWP {
    $sideBarWidth: 300px;
  
    .assessmentWPMain {
      background: $color_white;
      box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
  
      .modulesLeftNav {
        background: $color_white;
        box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        width: 100%;
        max-width: $sideBarWidth;
        height: calc(100vh - #{$topNavHeight} - 85px);
        position: sticky;
        top: calc(#{$topNavHeight} + 10px);
        overflow-y: auto;
        // float: left;

        &.active{
          height: calc(100vh - #{$topNavHeight} - 20px);
        }
  
        .modulesList {
          position: relative;
          border: none;
          background-color: transparent;
          border-bottom: 1px solid #E5E5E5;
          font-weight: $fontMedium;
  
          // &::after{
          //   content: '';
          //   position: absolute;
          //   width: 100%;
          //   max-width: 90%;
          //   height: 1px;
          //   background-color: #737373;
          //   bottom: 0;
          //   left: 50%;
          //   transform: translateX(-50%);
          // }
          &:last-child{
            border: none;
          }
  
          .moduleNo {
            $height: 30px;
            width: $height;
            height: $height;
            @include flex_center;
            color: $color_white;
            background: #000000;
            border-radius: 5px;
          }
  
          .p-text {
            @include text_ellipsis(1);
          }
  
          .moduleStatus {
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: #9b9b9b;
            display: flex;
            align-items: center;
            justify-content: center;
  
            svg {
              width: 8px;
              height: auto;
  
              path {
                fill: #fff;
              }
            }

          &.active {
            background-color: #00DE3E;
          }
          }
  
          &.active {
            .moduleNo {
              background-color: $primary_color;
            }
  
            .p-text {
              color: $primary_color;
            }
          }
        }
      }
  
      .modulesRightQu {
        width: 100%;
        max-width: calc(100% - #{$sideBarWidth});
        // float: right;
  
        .introModule {
          .introModuleTop {
            $minHeight: 400px;
            $maxHeight: 40vh;
            position: relative;
            height: $maxHeight;
            // @if $maxHeight < $minHeight  {
            //   height: $maxHeight;
            // } @else {
            //   height: $minHeight;
            // };
            // overflow: hidden;
  
            .assessmentBGImg {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 0;
  
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
  
            .introTopFloatingContent {
              color: $color_white;
              position: relative;
              z-index: 1;
  
              .floatingConLeft {
                h6.subtitle-text {
                  font-size: 1rem;
                }
  
                .p-text {
                  font-weight: 400;
  
                  b {
                    font-weight: 600;
                  }
                }
              }
            }
          }
  
          .introModuleContent {
            h5.p-text {
              font-weight: 700;
            }
  
            p.p-text {
              font-weight: $fontRegular;
            }
  
            .introInstructions {
              .IINo {
                $circleAspectRatio: 40px;
                width: $circleAspectRatio;
                height: $circleAspectRatio;
                background: #000000;
                border-radius: 50%;
                color: $color_white;
                @include flex_center;
              }
            }
          }
        }
  
        .assessmentWQP{
          .noBg{
            button{
              box-shadow: none;
            }
          }
          .assessmentWQPModleHead{
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
          }
          .assessmentWQPList{
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            &:last-child {
              border: none;
            }
            .questionTitle{
              .p-text {
                font-weight: $fontRegular;
                span{
                  color: #000000;
                  font-weight: $fontBold;
                }
              }
            }
          }
        }
      }
    }
  }


//  horizontalForm
.horizontalForm {
    fieldset {
        top: 0;
  
        legend {
            display: none;
        }
    }
  
    .formGroup {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #2F2F2F;
        .disableLable{
            color:#8D8D8D;
        }
  
        label{
          font-size: .8rem;
          font-weight: $fontSemiBold;
        }
  
        button{
            background-color: transparent;
           padding: 0; 
           border: none ;
          }
  
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
            font-weight: 400;
            font-style: normal;
            font-size: 15px;
            color: #2F2F2F;
            
        }
  
        .css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
            font-weight: 600;
            font-size: 16px;
            &::placeholder{
                font-style: italic;
            }
        }
        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
            height: 15px;
        }
  
        .css-i4bv87-MuiSvgIcon-root {
            color: #2F2F2F;
  
            &:hover {
                color: #6946C6;
            }
        }
  
        fieldset {
            border: 1px solid #EBEBEB !important;
            box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
            border-radius: 3px;
        }
    }
  }