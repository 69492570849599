// assessmentManagementSection
.projectEmptySection {
    .saveButton {
        button {
            box-shadow: none;
        }
    }

    .emptyAssessmentScreen {
        min-height: calc(100vh - 170px);
        @include flex_center;

        .p-text {
            span {
                color: $color_black;
                font-weight: $fontRegular;
                font-style: italic;
            }
        }

        .customButton {
            max-width: 250px;
            margin: 0 auto;
        }
    }
}
