.assessmentLandingSection {
  .assessmentTopSticky {
    position: sticky;
    top: $topNavHeight;
    background-color: $color_white;
    z-index: 1;
  }
}



/* 
    Button red class = failedBtn
    Button green class = successBtn
    Button disabled class = disabledBtn and need to add disabled attribute
*/
.customButton {
  button,
  a {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 8px 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: #fff;
    user-select: none;
    background-color: $primary_color;
    overflow: hidden;
    justify-content: center;
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: $button_hover;
    }

    svg {
      height: auto;

      path {
        fill: #fff;
      }
    }

    div {
      display: flex;
      justify-content: center;

      p {
        margin-left: 10px;
      }
    }

    .buttonLoader {
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 2;
      background-color: $primary_color;
      cursor: auto;

      .MuiCircularProgress-colorPrimary {
        color: #fff;
      }
    }

    &.disabledBtn {
      cursor: auto;
      background-color: #9b9b9b;
    }

    &.newDBconnectBtn {
      svg {
        transform: rotate(45deg);
      }
    }

    &.successBtn {
      cursor: auto;
      background-color: $sucess-color;
    }

    &.failedBtn {
      background-color: $fail-color;
    }
  }

  &.noBg {

    button,
    a {
      background-color: transparent;
      color: $primary_color;
      font-weight: $fontSemiBold;

      svg {
        width: 20px;

        path {
          fill: $primary_color;
        }
      }
    }
  }
}


// imported varient components style files
@import "./assessmentList/assessmentList";
@import "./assessmentWriter/assessmentWriter";