.createUserPopup{
    border-radius: 8px;
    .cancelButton{
        background: none;
        border: none;
    }
    .createUserItems{
        .inputField{
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
            border-radius: 8px; 
        }
        .PVUsersRow{
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
            border-radius: 8px;
            .PVUsersChild{
                position: relative;
                border: 0.5px solid #D5D5D5;
                border-radius: 50px;

                span {
                    position: relative;
                    $CH: 25px;
                    width: $CH;
                    height: $CH;
                    display: block;
                    background-color: #79D8C7;
                    border-radius: 50%;
                    @include flex_center;
                    color: $color_white;
                    font-size: 10px;
                    font-weight: $fontSemiBold;

                    svg {
                        position: absolute;
                        width: 20px;
                        height: auto;
                        top: -11px;
                        left: -5px;
                    }
                }

                .PVUserName {
                    color: #4E4E4E;
                    font-size: 12px;
                    font-weight: $fontSemiBold;
                    input{
                        color: #ffffff;
                        font-size: 12px;
                        font-weight: $fontSemiBold;
                        background-color: transparent;
                        border: none;
                        &::placeholder{
                            color: #ffffff;
                            opacity: 1;
                        }
                    }
                }

                .PVRemoveUser {
                    background-color: transparent;
                    border: none;
                    position: absolute;
                    width: 25px;
                    height: auto;
                    right: 3px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.addAdminButton {
                    background-color: $primary_color;
                    width: 80px;

                    span {
                        all: unset;
                        display: flex;

                        svg {
                            all: unset;
                            position: relative;
                            $CH: 20px;
                            width: $CH;
                            height: $CH;

                            path {
                                fill: $color_white;
                            }
                        }
                    }

                    .PVUserName {
                        color: $color_white;
                    }
                }
            }
        }
        .createUserButton{
            .cancelButton{
                background-color: #DADADA;
                border: none;
                border-radius: 8px;
            }
            .createButton{
                background-color: #6946C6;
                color: #FFFFFF;
                border: none;
                border-radius: 8px;
            }
        }
    }
}