// main appbar

.header-appbar {
    background-color: #000000 !important;
    box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.25) !important;
    top: 0;
    left: auto;
    right: 0;
    position: fixed;
    width: 100%;
    z-index: 101;

    .header-toolbar {
        .headerToolbarMenu {
            display: none;
            .headerToolbarMenuBtn{
                background-color: transparent;
            }
        }

        .rightToolbar {
            p {
                color: $color_white;
            }
        }
    }
}
/* To fix the height in header bar */
.header-toolbar {
    min-height: 5rem !important;
    height: 5.25rem;
}
/* styles for header title text */
.header-title {
    font-size: 36px !important;
    font-family: "Montserrat" !important;
    font-style: normal;
    color: #ffffff;
}

.header-data-text {
    font-weight: lighter !important;
}

.header-span-text {
    font-weight: 500;
}

/* topNavExtraSpace */
.topNavExtraSpace {
    height: $topNavHeight;
}

@media(max-width: 600px) {
    /* topNavExtraSpace */
    .topNavExtraSpace {
        height: 150px;
    }
}

// Mobile responsive

@media(max-width: 1060px){
    .header-appbar{
        .headerToolbarMenu{
            display: block;
            margin-right: 10px;
            button{
                all: unset;
                cursor: pointer;
                svg{
                    width: 22px;
                    height: auto;
                }
            }
        }
    }
}