// settingsCreation
.settingsCreationSection {
    .settingsImage {
        position: relative;

        .settingsImageUpload {
            $roundWidth: 43px;
            position: absolute;
            height: $roundWidth;
            width: $roundWidth;
            border-radius: 50%;
            top: 2vh;
            right: 2vh;
            z-index: 2;
            background-color: $color_white;
            border: none;
            @include medium_shadow;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }

            svg {
                display: inline-block;
                width: 20px;
                height: auto;
            }
        }

        .settingsImagePreview {
            width: 100%;
            height: 40vh;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            ;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            // background-color: rgba($color: #000000, $alpha: 0.3);
        }
    }

    .settingsInputsForm {
        width: 100%;
        max-width: 80%;
        margin: 0 auto;

        @mixin label_Common_Style {
            display: block;
            margin-top: 20px;
            margin-bottom: 8px;
            font-weight: $fontSemiBold;
            font-size: 1rem;
            color: #404040;
            padding-left: 5px;
        }

        .formGroup {
            label {
                @include label_Common_Style;
            }

            input,
            textarea {
                @include input_style;
            }

            textarea {
                resize: none;
                height: 80px;
            }
        }

        .selectFormGroup {
            label {
                @include label_Common_Style;
            }

            input{
                font-weight: $fontRegular;
                font-size: 1rem;
                color: #404040;
                &::placeholder {
                    // color: #404040;
                    font-style: italic;
                    font-size: 0.9rem;
                    opacity: 1;
                }
            }

            fieldset {
                border: 1px solid #EBEBEB;
                box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
                border-radius: 8px;
            }
        }
    }
}