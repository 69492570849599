// ================= searchComponent =============================
.searchComponent {
    $height: 50px;
    input {
        padding: 8px 16px;
        width: 359px;
        height: $height;
        background: #F5F5F5;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 13px;
        font-weight: 500;
        font-size: 16px;
        color: #757575;
    }

    .customButton {
        transform: translateX(-16px);
    }

    button {
        height: $height;
        border-radius: 13px;

        svg {
            width: 28px;
        }
    }
}

// ================= filterComponent =============================

.filterComponent {
    width: 100%;
    max-width: 200px;
    @include medium_shadow;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;

    .filterIcon {
        padding: 16.5px 0px;
        padding-left: 12px;
    }

    .MuiFormControl-root {
        fieldset {
            border: none;
        }
    }

}