// assessmentManagementSection
.assessmentManagementSection{
    .saveButton{
        button{
            box-shadow: none;
        }
    }
    .emptyAssessmentScreen{
        min-height: calc(100vh - 170px);
        @include flex_center;
        .p-text{
            span{
                color: $color_black;
                font-weight: $fontRegular;
                font-style: italic;
            }
        }
        .customButton{
            max-width: 250px;
            margin: 0 auto;
        }
    }
}

.navTabs{
    .nav-tabs{
        border: none;
        .nav-link {
            border: none;
            padding: 8px 0;
            margin: 0 16px;
            border-bottom: 2px solid transparent;
            color: #2F2F2F;
            font-weight: $fontRegular;
            &.active{
                font-weight: $fontMedium;
                border-bottom: 2px solid $primary_color;
            }
        }
    }
}

@import "./assessmentCreation/assessmentCreation";
@import "./assessmentCreation/instructionCreation/instructionCreation";