@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

.MuiTabs-indicator {
  color: #2f2f2f !important;
  background-color: #6946C6 !important;
}

a,
a:hover {
  text-decoration: none;
  color: #6946C6;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

button,
select,
input {
  outline: none !important;
  white-space: nowrap;
}

label,
ul {
  margin: 0;
}

.p-text {
  font-size: 16px;
  font-weight: 500;
}

.p-text strong {
  font-weight: 600;
}

.p-text span {
  color: #6946C6;
}

.h1-text {
  font-size: 35px;
  line-height: 45px;
  font-weight: 800;
}

.h1-text span {
  color: #6946C6;
}

.title-text {
  font-size: 48px;
  line-height: 55px;
  font-weight: 800;
}

.title-text span {
  color: #6946C6;
}

.subtitle-text {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}

.subtitle-text span {
  color: #6946C6;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (max-width: 600px) {
  .p-text {
    font-size: 15px;
  }
  .h1-text {
    font-size: 22px;
    line-height: 25px;
  }
  .title-text {
    font-size: 35px;
    line-height: 40px;
  }
  .subtitle-text {
    font-size: 22px;
    line-height: 25px;
  }
}

.container {
  max-width: 1400px;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 600px) {
  .container {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .navBarHead .navBar {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

.header-appbar {
  background-color: #000000 !important;
  -webkit-box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.25) !important;
          box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.25) !important;
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  width: 100%;
  z-index: 101;
}

.header-appbar .header-toolbar .headerToolbarMenu {
  display: none;
}

.header-appbar .header-toolbar .headerToolbarMenu .headerToolbarMenuBtn {
  background-color: transparent;
}

.header-appbar .header-toolbar .rightToolbar p {
  color: #FFFFFF;
}

/* To fix the height in header bar */
.header-toolbar {
  min-height: 5rem !important;
  height: 5.25rem;
}

/* styles for header title text */
.header-title {
  font-size: 36px !important;
  font-family: "Montserrat" !important;
  font-style: normal;
  color: #ffffff;
}

.header-data-text {
  font-weight: lighter !important;
}

.header-span-text {
  font-weight: 500;
}

/* topNavExtraSpace */
.topNavExtraSpace {
  height: 84px;
}

@media (max-width: 600px) {
  /* topNavExtraSpace */
  .topNavExtraSpace {
    height: 150px;
  }
}

@media (max-width: 1060px) {
  .header-appbar .headerToolbarMenu {
    display: block;
    margin-right: 10px;
  }
  .header-appbar .headerToolbarMenu button {
    all: unset;
    cursor: pointer;
  }
  .header-appbar .headerToolbarMenu button svg {
    width: 22px;
    height: auto;
  }
}

.mainApp .sidebarAside {
  position: relative;
  width: 280px;
  float: left;
  -webkit-transition: width, left, right, 0.3s;
  transition: width, left, right, 0.3s;
}

.mainApp .sidebarAside .sidebar {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 280px;
  height: calc(100vh - 84px);
  color: #fff;
  -webkit-transition: width, left, right, 0.3s;
  transition: width, left, right, 0.3s;
  z-index: 1009;
}

.mainApp .mainPages {
  position: relative;
  width: calc(100% - 280px);
  -webkit-transition: width, left, right, 0.3s;
  transition: width, left, right, 0.3s;
  float: right;
}

.mainApp.collapsed .sidebarAside {
  width: 80px;
}

.mainApp.collapsed .sidebarAside .sidebar {
  width: 80px;
}

.mainApp.collapsed .sidebarAside .sidebar .sidebarCollapsed svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.mainApp.collapsed .mainPages {
  width: calc(100% - 80px);
}

.sidebar .sidebarCollapsed {
  position: absolute;
  top: 50%;
  right: -15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #1F1F1F;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 1010;
  display: -ms-grid;
  display: grid;
  place-items: center;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.sidebar .sidebarCollapsed svg {
  width: 13px;
  height: auto;
}

.sidebar .pro-sidebar {
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.sidebar .pro-sidebar .pro-sidebar-inner {
  background-color: #1F1F1F;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item .pro-icon-wrapper {
  position: relative;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item .pro-icon-wrapper::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0%;
  height: 2px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #6946C6;
  -webkit-transition: .2s;
  transition: .2s;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item .pro-icon-wrapper .pro-icon svg {
  width: 21px;
  height: auto;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item .pro-icon-wrapper .pro-icon svg path {
  fill: #CDCDCD;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item .pro-inner-item::before {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.active .pro-inner-item .pro-icon-wrapper::after, .sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.firstLevelMenu.open .pro-inner-item .pro-icon-wrapper::after {
  width: 70%;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.active .pro-inner-item .pro-icon svg path, .sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu .pro-menu-item.firstLevelMenu.open .pro-inner-item .pro-icon svg path {
  fill: #fff;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu.submenu-bullets .pro-menu-item .pro-menu-item .pro-inner-item::before {
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 6px;
  max-width: 6px;
  height: 6px;
  background-color: #C4C4C4 !important;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu.submenu-bullets .pro-menu-item .pro-menu-item.open .pro-inner-item::before {
  background-color: #6946C6 !important;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu.submenu-bullets .pro-menu-item .pro-menu-item.proMenuItems .pro-inner-item::before {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #C4C4C4 !important;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-menu.submenu-bullets .pro-menu-item .pro-menu-item.proMenuItems.active .pro-inner-item::before {
  background-color: #6946C6 !important;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .pro-menu {
  padding-bottom: 0;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu {
  padding-bottom: 0;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-content .pro-menu .pro-menu-item .react-slidedown {
  margin-top: 5px;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer {
  position: relative;
  border: none;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #6B6B6B;
  width: 80%;
  height: .5px;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .pro-item-content span {
  color: #fff;
  font-weight: 600;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 35px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 43%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 40%;
  width: 1px;
  background-color: #adadad;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex .sidebarFooterHelp,
.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex .sidebarFooterVersion {
  font-size: 13px;
  padding: 0 10px;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div a {
  font-size: 13px;
  color: #adadad;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div a:hover, .sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div a:active {
  color: #d8d8d8;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div .d-flex .sidebarFooterTitle {
  font-size: 13px;
  padding-left: 10px;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div .d-flex.active .sidebarFooterIcon {
  position: relative;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer .sidebarFooterFlex div .d-flex.active .sidebarFooterIcon::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: -3px;
  width: 20px;
  height: 2px;
  background-color: #6946C6;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer.collapsed::before {
  height: 0;
}

.sidebar .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer.collapsed .sidebarFooterFlex {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  display: none;
}

@media (max-width: 1060px) {
  .mainApp .sidebarAside {
    width: 0px;
  }
  .mainApp .sidebarAside .sidebar {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  .mainApp .mainPages {
    width: calc(100% - 0px);
  }
  .mainApp.collapsed .sidebarAside {
    max-width: 0px;
    min-width: 0px;
  }
  .mainApp.menuActive .sidebarAside .sidebar {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

.searchComponent input {
  padding: 8px 16px;
  width: 359px;
  height: 50px;
  background: #F5F5F5;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  font-weight: 500;
  font-size: 16px;
  color: #757575;
}

.searchComponent .customButton {
  -webkit-transform: translateX(-16px);
          transform: translateX(-16px);
}

.searchComponent button {
  height: 50px;
  border-radius: 13px;
}

.searchComponent button svg {
  width: 28px;
}

.filterComponent {
  width: 100%;
  max-width: 200px;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.08);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.08);
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
}

.filterComponent .filterIcon {
  padding: 16.5px 0px;
  padding-left: 12px;
}

.filterComponent .MuiFormControl-root fieldset {
  border: none;
}

.assessmentLandingSection .assessmentTopSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 84px;
  background-color: #FFFFFF;
  z-index: 1;
}

/* 
    Button red class = failedBtn
    Button green class = successBtn
    Button disabled class = disabledBtn and need to add disabled attribute
*/
.customButton button,
.customButton a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #6946C6;
  overflow: hidden;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
}

.customButton button:hover,
.customButton a:hover {
  background-color: #523A92;
}

.customButton button svg,
.customButton a svg {
  height: auto;
}

.customButton button svg path,
.customButton a svg path {
  fill: #fff;
}

.customButton button div,
.customButton a div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.customButton button div p,
.customButton a div p {
  margin-left: 10px;
}

.customButton button .buttonLoader,
.customButton a .buttonLoader {
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
  background-color: #6946C6;
  cursor: auto;
}

.customButton button .buttonLoader .MuiCircularProgress-colorPrimary,
.customButton a .buttonLoader .MuiCircularProgress-colorPrimary {
  color: #fff;
}

.customButton button.disabledBtn,
.customButton a.disabledBtn {
  cursor: auto;
  background-color: #9b9b9b;
}

.customButton button.newDBconnectBtn svg,
.customButton a.newDBconnectBtn svg {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.customButton button.successBtn,
.customButton a.successBtn {
  cursor: auto;
  background-color: #46C66A;
}

.customButton button.failedBtn,
.customButton a.failedBtn {
  background-color: #FF4B55;
}

.customButton.noBg button,
.customButton.noBg a {
  background-color: transparent;
  color: #6946C6;
  font-weight: 600;
}

.customButton.noBg button svg,
.customButton.noBg a svg {
  width: 20px;
}

.customButton.noBg button svg path,
.customButton.noBg a svg path {
  fill: #6946C6;
}

.myAssessmentsList .AP_Img {
  width: 220px;
  height: 100%;
}

.myAssessmentsList .AP_Img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.myAssessmentsList .publishDetails .p-text .nt {
  color: #737373;
}

.myAssessmentsList .listItem {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.myAssessmentsList .listItem .myAssessmentLeftCon .subtitle-text {
  font-weight: bold;
}

.myAssessmentsList .listItem .myAssessmentLeftCon h6.subtitle-text {
  color: #6946C6;
  font-weight: bold;
  font-size: 1.2rem;
}

.myAssessmentsList .listItem .myAssessmentLeftCon .p-text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: .9rem;
  max-width: 80%;
}

.myAssessmentsList .listItem .myAssessmentDates {
  font-size: 14px;
}

.myAssessmentsList .listItem .myAssessmentDates .editAssessmentAction {
  width: 45px;
  height: 45px;
  background-color: #6946C6;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
}

.myAssessmentsList .listItem .myAssessmentDates .editAssessmentAction svg path {
  fill: #FFFFFF;
}

.assessmentWPBreadcrumbs button {
  border: none;
  background-color: transparent;
  font-weight: 300;
}

.assessmentWPBreadcrumbs .MuiBreadcrumbs-separator {
  color: #6946C6;
  font-weight: 500;
}

.assessmentWPBreadcrumbs .MuiBreadcrumbs-separator svg {
  width: 8px;
  height: auto;
}

.assessmentWPBreadcrumbs .MuiBreadcrumbs-separator svg path {
  fill: #6946C6;
}

.assessmentWPBreadcrumbs div {
  color: #6946C6;
  font-weight: 500;
}

.assessmentWP .assessmentWPMain {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
}

.assessmentWP .assessmentWPMain .modulesLeftNav {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
  height: calc(100vh - 84px - 85px);
  position: -webkit-sticky;
  position: sticky;
  top: calc(84px + 10px);
  overflow-y: auto;
}

.assessmentWP .assessmentWPMain .modulesLeftNav.active {
  height: calc(100vh - 84px - 20px);
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList {
  position: relative;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #E5E5E5;
  font-weight: 500;
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList:last-child {
  border: none;
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList .moduleNo {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FFFFFF;
  background: #000000;
  border-radius: 5px;
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList .p-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList .moduleStatus {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #9b9b9b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList .moduleStatus svg {
  width: 8px;
  height: auto;
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList .moduleStatus svg path {
  fill: #fff;
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList .moduleStatus.active {
  background-color: #00DE3E;
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList.active .moduleNo {
  background-color: #6946C6;
}

.assessmentWP .assessmentWPMain .modulesLeftNav .modulesList.active .p-text {
  color: #6946C6;
}

.assessmentWP .assessmentWPMain .modulesRightQu {
  width: 100%;
  max-width: calc(100% - 300px);
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleTop {
  position: relative;
  height: 40vh;
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleTop .assessmentBGImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleTop .assessmentBGImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleTop .introTopFloatingContent {
  color: #FFFFFF;
  position: relative;
  z-index: 1;
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleTop .introTopFloatingContent .floatingConLeft h6.subtitle-text {
  font-size: 1rem;
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleTop .introTopFloatingContent .floatingConLeft .p-text {
  font-weight: 400;
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleTop .introTopFloatingContent .floatingConLeft .p-text b {
  font-weight: 600;
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleContent h5.p-text {
  font-weight: 700;
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleContent p.p-text {
  font-weight: 400;
}

.assessmentWP .assessmentWPMain .modulesRightQu .introModule .introModuleContent .introInstructions .IINo {
  width: 40px;
  height: 40px;
  background: #000000;
  border-radius: 50%;
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.assessmentWP .assessmentWPMain .modulesRightQu .assessmentWQP .noBg button {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.assessmentWP .assessmentWPMain .modulesRightQu .assessmentWQP .assessmentWQPModleHead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.assessmentWP .assessmentWPMain .modulesRightQu .assessmentWQP .assessmentWQPList {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.assessmentWP .assessmentWPMain .modulesRightQu .assessmentWQP .assessmentWQPList:last-child {
  border: none;
}

.assessmentWP .assessmentWPMain .modulesRightQu .assessmentWQP .assessmentWQPList .questionTitle .p-text {
  font-weight: 400;
}

.assessmentWP .assessmentWPMain .modulesRightQu .assessmentWQP .assessmentWQPList .questionTitle .p-text span {
  color: #000000;
  font-weight: 700;
}

.horizontalForm fieldset {
  top: 0;
}

.horizontalForm fieldset legend {
  display: none;
}

.horizontalForm .formGroup {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #2F2F2F;
}

.horizontalForm .formGroup .disableLable {
  color: #8D8D8D;
}

.horizontalForm .formGroup label {
  font-size: .8rem;
  font-weight: 600;
}

.horizontalForm .formGroup button {
  background-color: transparent;
  padding: 0;
  border: none;
}

.horizontalForm .formGroup .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  color: #2F2F2F;
}

.horizontalForm .formGroup .css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  font-weight: 600;
  font-size: 16px;
}

.horizontalForm .formGroup .css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input::-webkit-input-placeholder {
  font-style: italic;
}

.horizontalForm .formGroup .css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input:-ms-input-placeholder {
  font-style: italic;
}

.horizontalForm .formGroup .css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input::-ms-input-placeholder {
  font-style: italic;
}

.horizontalForm .formGroup .css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input::placeholder {
  font-style: italic;
}

.horizontalForm .formGroup .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 15px;
}

.horizontalForm .formGroup .css-i4bv87-MuiSvgIcon-root {
  color: #2F2F2F;
}

.horizontalForm .formGroup .css-i4bv87-MuiSvgIcon-root:hover {
  color: #6946C6;
}

.horizontalForm .formGroup fieldset {
  border: 1px solid #EBEBEB !important;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
}

.assessmentManagementSection .saveButton button {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.assessmentManagementSection .emptyAssessmentScreen {
  min-height: calc(100vh - 170px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.assessmentManagementSection .emptyAssessmentScreen .p-text span {
  color: #000000;
  font-weight: 400;
  font-style: italic;
}

.assessmentManagementSection .emptyAssessmentScreen .customButton {
  max-width: 250px;
  margin: 0 auto;
}

.navTabs .nav-tabs {
  border: none;
}

.navTabs .nav-tabs .nav-link {
  border: none;
  padding: 8px 0;
  margin: 0 16px;
  border-bottom: 2px solid transparent;
  color: #2F2F2F;
  font-weight: 400;
}

.navTabs .nav-tabs .nav-link.active {
  font-weight: 500;
  border-bottom: 2px solid #6946C6;
}

.assessmentCreationSection .saveButton button {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.assessmentCreationSection .assessmentWPMain .ACModuleSection .modulesLeftNav {
  width: 300px;
}

.assessmentCreationSection .assessmentWPMain .ACModuleSection .modulesLeftNav .moduleStatus {
  background-color: transparent;
  height: auto;
  width: auto;
}

.assessmentCreationSection .assessmentWPMain .ACModuleSection .modulesLeftNav .moduleStatus svg {
  width: auto;
}

.assessmentCreationSection .assessmentWPMain .ACModuleSection .modulesLeftNav .moduleNo.addModule {
  all: unset;
}

.assessmentCreationSection .assessmentWPMain .ACModuleSection .modulesLeftNav .modulesListHead {
  border: 1px solid transparent;
  border-bottom-color: #E5E5E5;
}

.assessmentCreationSection .assessmentWPMain .ACModuleSection .modulesLeftNav .modulesListHead.selected {
  border-bottom-color: transparent;
}

.assessmentCreationSection .assessmentWPMain .ACModuleSection .modulesLeftNav .modulesListHead.selected .modulesList.active .moduleNo {
  background: #000000;
}

.assessmentCreationSection .assessmentWPMain .ACModuleSection .modulesLeftNav .modulesListHead.selected .modulesList.active .p-text {
  color: #000000;
}

.assessmentCreationSection .assessmentWPMain .ACModuleSection .modulesLeftNav button {
  all: unset;
}

.settingsCreationSection .settingsImage {
  position: relative;
}

.settingsCreationSection .settingsImage .settingsImageUpload {
  position: absolute;
  height: 43px;
  width: 43px;
  border-radius: 50%;
  top: 2vh;
  right: 2vh;
  z-index: 2;
  background-color: #FFFFFF;
  border: none;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.08);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.08);
}

.settingsCreationSection .settingsImage .settingsImageUpload input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.settingsCreationSection .settingsImage .settingsImageUpload svg {
  display: inline-block;
  width: 20px;
  height: auto;
}

.settingsCreationSection .settingsImage .settingsImagePreview {
  width: 100%;
  height: 40vh;
}

.settingsCreationSection .settingsImage .settingsImagePreview img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.settingsCreationSection .settingsImage::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.settingsCreationSection .settingsInputsForm {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
}

.settingsCreationSection .settingsInputsForm .formGroup label {
  display: block;
  margin-top: 20px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 1rem;
  color: #404040;
  padding-left: 5px;
}

.settingsCreationSection .settingsInputsForm .formGroup input,
.settingsCreationSection .settingsInputsForm .formGroup textarea {
  width: 100%;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 10px;
  font-weight: 400;
  font-size: 1rem;
  color: #404040;
  outline: none;
}

.settingsCreationSection .settingsInputsForm .formGroup input::-webkit-input-placeholder,
.settingsCreationSection .settingsInputsForm .formGroup textarea::-webkit-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.settingsCreationSection .settingsInputsForm .formGroup input:-ms-input-placeholder,
.settingsCreationSection .settingsInputsForm .formGroup textarea:-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.settingsCreationSection .settingsInputsForm .formGroup input::-ms-input-placeholder,
.settingsCreationSection .settingsInputsForm .formGroup textarea::-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.settingsCreationSection .settingsInputsForm .formGroup input::placeholder,
.settingsCreationSection .settingsInputsForm .formGroup textarea::placeholder {
  font-style: italic;
  opacity: 1;
}

.settingsCreationSection .settingsInputsForm .formGroup textarea {
  resize: none;
  height: 80px;
}

.settingsCreationSection .settingsInputsForm .selectFormGroup label {
  display: block;
  margin-top: 20px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 1rem;
  color: #404040;
  padding-left: 5px;
}

.settingsCreationSection .settingsInputsForm .selectFormGroup input {
  font-weight: 400;
  font-size: 1rem;
  color: #404040;
}

.settingsCreationSection .settingsInputsForm .selectFormGroup input::-webkit-input-placeholder {
  font-style: italic;
  font-size: 0.9rem;
  opacity: 1;
}

.settingsCreationSection .settingsInputsForm .selectFormGroup input:-ms-input-placeholder {
  font-style: italic;
  font-size: 0.9rem;
  opacity: 1;
}

.settingsCreationSection .settingsInputsForm .selectFormGroup input::-ms-input-placeholder {
  font-style: italic;
  font-size: 0.9rem;
  opacity: 1;
}

.settingsCreationSection .settingsInputsForm .selectFormGroup input::placeholder {
  font-style: italic;
  font-size: 0.9rem;
  opacity: 1;
}

.settingsCreationSection .settingsInputsForm .selectFormGroup fieldset {
  border: 1px solid #EBEBEB;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}

.questionsCreation .questionBox .questionBoxChild {
  background: #FFFFFF;
  border: 2px solid transparent;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildLeft span {
  font-weight: 600;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionFormGroup textarea {
  width: 100%;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 10px;
  font-weight: 400;
  font-size: 1rem;
  color: #404040;
  outline: none;
  height: 80px;
  resize: none;
  font-weight: 400;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionFormGroup textarea::-webkit-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionFormGroup textarea:-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionFormGroup textarea::-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionFormGroup textarea::placeholder {
  font-style: italic;
  opacity: 1;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild {
  width: 100%;
  max-width: 400px;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .optionLabelCreation input {
  padding: 0 10px;
  border: 1px solid transparent;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .optionLabelCreation input::-webkit-input-placeholder {
  font-weight: 500;
  font-style: italic;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .optionLabelCreation input:-ms-input-placeholder {
  font-weight: 500;
  font-style: italic;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .optionLabelCreation input::-ms-input-placeholder {
  font-weight: 500;
  font-style: italic;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .optionLabelCreation input::placeholder {
  font-weight: 500;
  font-style: italic;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .optionLabelCreation input:active, .questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .optionLabelCreation input:focus {
  border-color: #EBEBEB;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup .d-flex {
  width: 100%;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 10px;
  font-weight: 400;
  font-size: 1rem;
  color: #404040;
  outline: none;
  padding: 0;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup .d-flex::-webkit-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup .d-flex:-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup .d-flex::-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup .d-flex::placeholder {
  font-style: italic;
  opacity: 1;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup input {
  padding: 10px;
  padding-right: 0;
  border: none;
  font-weight: 500;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup input::-webkit-input-placeholder {
  font-style: italic;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup input:-ms-input-placeholder {
  font-style: italic;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup input::-ms-input-placeholder {
  font-style: italic;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup input::placeholder {
  font-style: italic;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup button {
  border: none;
  padding: 10px 12px;
  background-color: transparent;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .formGroup button svg {
  width: 13px;
  height: auto;
}

.questionsCreation .questionBox .questionBoxChild .questionBoxChildRight .questionsOptionsCreation .questionsOptionsCreationChild .addOptionAction {
  border: none;
  background-color: transparent;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
}

.questionsCreation .questionBox .questionBoxChild .qestionAddOnOptions button {
  background-color: transparent;
  border: none;
}

.questionsCreation .questionBox .questionBoxChild .qestionAddOnOptions button svg path {
  fill: #CACACA;
  -webkit-transition: .2s;
  transition: .2s;
}

.questionsCreation .questionBox .questionBoxChild .qestionAddOnOptions button svg:hover path {
  fill: #6946C6;
}

.questionsCreation .addquestionAction .customButton button {
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
}

.instructionCreationSection .subtitle-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.instructionCreationSection .points .d-flex .instNumber {
  width: 36px;
  height: 36px;
  background-color: #000000;
  color: #FFFFFF;
  border-radius: 50%;
  display: -ms-grid;
  display: grid;
  place-items: center;
  font-weight: 600;
}

.instructionCreationSection .points .d-flex textarea {
  width: 100%;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 10px;
  font-weight: 400;
  font-size: 1rem;
  color: #404040;
  outline: none;
  resize: none;
  height: 80px;
}

.instructionCreationSection .points .d-flex textarea::-webkit-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.instructionCreationSection .points .d-flex textarea:-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.instructionCreationSection .points .d-flex textarea::-ms-input-placeholder {
  font-style: italic;
  opacity: 1;
}

.instructionCreationSection .points .d-flex textarea::placeholder {
  font-style: italic;
  opacity: 1;
}

.instructionCreationSection .addPointAction button {
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0 20px;
  background-color: transparent;
  border: none;
  margin: 0 auto;
}

.projectListSection .assessmentTopSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 84px;
  background-color: #FFFFFF;
  z-index: 1;
}

.projectListSection .customButton.noBg button {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.projectListSection .projectList .projectListChild {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.projectListSection .projectList .projectListChild .PLBox {
  width: 70px;
  height: 70px;
  background: #79D8C7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  font-size: 1.5rem;
  color: #FFFFFF;
  font-weight: 600;
}

.projectListSection .projectList .projectListChild .PLDetails .p-text {
  font-weight: 400;
}

.projectListSection .projectList .projectListChild button {
  border: none;
  background-color: transparent;
}

.projectListSection .projectList .projectListChild button svg path {
  fill: #6946C6;
}

.projectListSection .projectList .projectListChild .PLActions .lineLeft {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.projectListSection .projectList .projectListChild .PLActions .lineLeft button.disabled svg path {
  fill: #BEBEBE;
}

.projectViewSection .customButton.noBg button {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.projectViewSection .PVDetails {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.projectViewSection .PVDetails .PVBox {
  width: 70px;
  height: 70px;
  background: #79D8C7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  font-size: 1.5rem;
  color: #FFFFFF;
  font-weight: 600;
}

.projectViewSection .PVDetails .PVDetailCon .subtitle-text input {
  border: 1px solid transparent;
  font-weight: 600;
}

.projectViewSection .PVDetails .PVDetailCon .subtitle-text input:active, .projectViewSection .PVDetails .PVDetailCon .subtitle-text input:focus, .projectViewSection .PVDetails .PVDetailCon .subtitle-text input:hover {
  border-color: #000;
}

.projectViewSection .PVDetails .PVDetailCon .subtitle-text input::-webkit-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDetails .PVDetailCon .subtitle-text input:-ms-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDetails .PVDetailCon .subtitle-text input::-ms-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDetails .PVDetailCon .subtitle-text input::placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDetails .PVDetailCon .p-text {
  font-weight: 400;
}

.projectViewSection .PVDetails .PVDetailCon .p-text input {
  border: 1px solid transparent;
  font-weight: 600;
}

.projectViewSection .PVDetails .PVDetailCon .p-text input:active, .projectViewSection .PVDetails .PVDetailCon .p-text input:focus, .projectViewSection .PVDetails .PVDetailCon .p-text input:hover {
  border-color: #000;
}

.projectViewSection .PVDetails .PVDetailCon .p-text input::-webkit-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDetails .PVDetailCon .p-text input:-ms-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDetails .PVDetailCon .p-text input::-ms-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDetails .PVDetailCon .p-text input::placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVUsers .PVUserTitle {
  font-size: 12px;
  font-weight: 600;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild {
  position: relative;
  border: 0.5px solid #D5D5D5;
  border-radius: 50px;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild span {
  position: relative;
  width: 25px;
  height: 25px;
  display: block;
  background-color: #79D8C7;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 600;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild span svg {
  position: absolute;
  width: 20px;
  height: auto;
  top: -11px;
  left: -5px;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild .PVUserName {
  color: #4E4E4E;
  font-size: 12px;
  font-weight: 600;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild .PVUserName input {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  border: none;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild .PVUserName input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild .PVUserName input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild .PVUserName input::-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild .PVUserName input::placeholder {
  color: #ffffff;
  opacity: 1;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild .PVRemoveUser {
  background-color: transparent;
  border: none;
  position: absolute;
  width: 25px;
  height: auto;
  right: 3px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild.addAdminButton {
  background-color: #6946C6;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild.addAdminButton span {
  all: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild.addAdminButton span svg {
  all: unset;
  position: relative;
  width: 20px;
  height: 20px;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild.addAdminButton span svg path {
  fill: #FFFFFF;
}

.projectViewSection .PVUsers .PVUsersRow .PVUsersChild.addAdminButton .PVUserName {
  color: #FFFFFF;
}

.projectViewSection .PVDDetails {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.projectViewSection .PVDDetails h1.subtitle-text {
  font-size: .8rem;
  font-weight: 600;
}

.projectViewSection .PVDDetails .PVDDetailsList {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.projectViewSection .PVDDetails .PVDDetailsList h5.subtitle-text {
  font-size: 1rem;
  font-weight: 600;
}

.projectViewSection .PVDDetails .PVDDetailsList h5.subtitle-text input {
  border: 1px solid transparent;
  font-weight: 600;
}

.projectViewSection .PVDDetails .PVDDetailsList h5.subtitle-text input:active, .projectViewSection .PVDDetails .PVDDetailsList h5.subtitle-text input:focus, .projectViewSection .PVDDetails .PVDDetailsList h5.subtitle-text input:hover {
  border-color: #000;
}

.projectViewSection .PVDDetails .PVDDetailsList h5.subtitle-text input::-webkit-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDDetails .PVDDetailsList h5.subtitle-text input:-ms-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDDetails .PVDDetailsList h5.subtitle-text input::-ms-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDDetails .PVDDetailsList h5.subtitle-text input::placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDDetails .PVDDetailsList .p-text {
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  max-width: 600px;
  color: #000000;
}

.projectViewSection .PVDDetails .PVDDetailsList .p-text input {
  border: 1px solid transparent;
  font-weight: 600;
}

.projectViewSection .PVDDetails .PVDDetailsList .p-text input:active, .projectViewSection .PVDDetails .PVDDetailsList .p-text input:focus, .projectViewSection .PVDDetails .PVDDetailsList .p-text input:hover {
  border-color: #000;
}

.projectViewSection .PVDDetails .PVDDetailsList .p-text input::-webkit-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDDetails .PVDDetailsList .p-text input:-ms-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDDetails .PVDDetailsList .p-text input::-ms-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDDetails .PVDDetailsList .p-text input::placeholder {
  font-weight: 400;
  font-style: italic;
}

.projectViewSection .PVDDetails .PVDDetailsList button {
  background-color: transparent;
  border: none;
}

.projectViewSection .PVDDetails .PVDDetailsList .PVDUsers {
  display: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.projectViewSection .PVDDetails .PVDDetailsList.active .PVDUsers {
  display: block;
}

.projectEmptySection .saveButton button {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.projectEmptySection .emptyAssessmentScreen {
  min-height: calc(100vh - 170px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.projectEmptySection .emptyAssessmentScreen .p-text span {
  color: #000000;
  font-weight: 400;
  font-style: italic;
}

.projectEmptySection .emptyAssessmentScreen .customButton {
  max-width: 250px;
  margin: 0 auto;
}

.fullPageLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1011;
}

.fullPageLoader svg {
  color: #fff;
}

.createUserPopup {
  border-radius: 8px;
}

.createUserPopup .cancelButton {
  background: none;
  border: none;
}

.createUserPopup .createUserItems .inputField {
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}

.createUserPopup .createUserItems .PVUsersRow {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  -webkit-box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
          box-shadow: 4px 4px 13px 3px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild {
  position: relative;
  border: 0.5px solid #D5D5D5;
  border-radius: 50px;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild span {
  position: relative;
  width: 25px;
  height: 25px;
  display: block;
  background-color: #79D8C7;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 600;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild span svg {
  position: absolute;
  width: 20px;
  height: auto;
  top: -11px;
  left: -5px;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild .PVUserName {
  color: #4E4E4E;
  font-size: 12px;
  font-weight: 600;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild .PVUserName input {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  border: none;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild .PVUserName input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild .PVUserName input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild .PVUserName input::-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild .PVUserName input::placeholder {
  color: #ffffff;
  opacity: 1;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild .PVRemoveUser {
  background-color: transparent;
  border: none;
  position: absolute;
  width: 25px;
  height: auto;
  right: 3px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild.addAdminButton {
  background-color: #6946C6;
  width: 80px;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild.addAdminButton span {
  all: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild.addAdminButton span svg {
  all: unset;
  position: relative;
  width: 20px;
  height: 20px;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild.addAdminButton span svg path {
  fill: #FFFFFF;
}

.createUserPopup .createUserItems .PVUsersRow .PVUsersChild.addAdminButton .PVUserName {
  color: #FFFFFF;
}

.createUserPopup .createUserItems .createUserButton .cancelButton {
  background-color: #DADADA;
  border: none;
  border-radius: 8px;
}

.createUserPopup .createUserItems .createUserButton .createButton {
  background-color: #6946C6;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
}
