.fullPageLoader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 1011;
    svg{
        color: #fff;
    }
}