// instructionCreation
.instructionCreationSection {
    .subtitle-text {
        font-weight: $fontSemiBold;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }
    .points{
        .d-flex{
            .instNumber{
                width: 36px;
                height: 36px;
                background-color: $color_black;
                color: $color_white;
                border-radius: 50%;
                display: grid;
                place-items: center;
                font-weight: $fontSemiBold;
            }
            textarea {
                @include input_style;
                resize: none;
                height: 80px;
            }
        }
    }
    .addPointAction{
        button{
            width: 100%;
            max-width: fit-content;
            padding: 0 20px;
            background-color: transparent;
            border: none;
            margin: 0 auto;
        }
    }
}