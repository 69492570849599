// Layout
.mainApp{
    .sidebarAside{
        position: relative;
        width: 280px;
        float: left;
        transition: width, left, right, 0.3s;
        .sidebar{
            position: fixed;
            left: 0;
            bottom: 0px;
            width: 280px;
            height: calc(100vh - 84px);
            color: #fff;
            transition: width, left, right, 0.3s;
            z-index: 1009;
        }
    }
    .mainPages{
        position: relative;
        width: calc(100% - 280px);
        transition: width, left, right, 0.3s;
        float: right;
    }
    &.collapsed{
        .sidebarAside{
            width: 80px;
            .sidebar{
                width: 80px;
                .sidebarCollapsed{
                    svg{
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .mainPages{
            width: calc(100% - 80px);
        }
    }
}

// sidebar
.sidebar{
    .sidebarCollapsed{
        position: absolute;
        top: 50%;
        right: -15px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background-color: #1F1F1F;
        border-radius: 50%;
        border: 1px solid rgba($color: #fff, $alpha: 0.5);
        z-index: 1010;
        display: grid;
        place-items: center;
        cursor: pointer;
        transition: transform 0.3s;
        svg{
            width: 13px;
            height: auto;
        }
    }
    .pro-sidebar{
        width: 100%;
        user-select: none;
        .pro-sidebar-inner{
            background-color: #1F1F1F;
            .pro-sidebar-layout{
                // common decalre
                .pro-menu{
                    .pro-menu-item{
                        .pro-inner-item{
                            .pro-icon-wrapper{
                                position: relative;
                                &::after{
                                    content: "";
                                    position: absolute;
                                    bottom: -5px;
                                    left: 50%;
                                    width: 0%;
                                    height: 2px;
                                    transform: translateX(-50%);
                                    background-color: $primary_color;
                                    transition: .2s;
                                }
                                .pro-icon{
                                    svg{
                                        width: 21px;
                                        height: auto;
                                        path{
                                            fill: #CDCDCD;
                                        }
                                    }
                                }
                            }
                            &::before{
                                box-shadow: none;
                            }
                        }
                        &.active,
                        &.firstLevelMenu.open{
                            .pro-inner-item{
                                .pro-icon-wrapper{
                                    &::after{
                                        width: 70%;
                                    }
                                }
                                .pro-icon{
                                    svg{
                                        path{
                                            fill: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.submenu-bullets{
                        .pro-menu-item{
                            .pro-menu-item{
                                .pro-inner-item{
                                    &::before{
                                        box-shadow: none;
                                        width: 6px;
                                        max-width: 6px;
                                        height: 6px;
                                        background-color: #C4C4C4 !important;
                                    }
                                }
                                &.open{
                                    .pro-inner-item{
                                        &::before{
                                            background-color: $primary_color !important;
                                        }
                                    }
                                }
                                &.proMenuItems{
                                    .pro-inner-item{
                                        &::before{
                                            box-shadow: none;
                                            background-color: #C4C4C4 !important;
                                        }
                                    }
                                    &.active{
                                        .pro-inner-item{
                                            &::before{
                                                background-color: $primary_color !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // Header
                .pro-sidebar-header{
                    border-bottom: none;
                    .pro-menu{
                        padding-bottom: 0;
                    }
                }

                // body
                .pro-sidebar-content{
                    .pro-menu{
                        padding-bottom: 0;
                        .pro-menu-item{
                            .react-slidedown{
                                margin-top: 5px;
                            }
                        }
                    }
                }

                // footer
                .pro-sidebar-footer{
                    position: relative;
                    border: none;
                    &::before{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #6B6B6B;
                        width: 80%;
                        height: .5px ;
                    }
                    .pro-item-content{
                        span{
                            color: #fff;
                            font-weight: 600;
                        }
                    }
                    .sidebarFooterFlex{
                        position: relative;
                        display: flex;
                        padding: 20px 35px;
                        justify-content: space-between;
                        transform: translateX(0%);
                        transition: transform 0.3s;

                        &::after{
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 43%;
                            transform: translateY(-50%);
                            height: 40%;
                            width: 1px;
                            background-color: #adadad;
                        }
                        .sidebarFooterHelp,
                        .sidebarFooterVersion{
                            font-size: 13px;
                            padding: 0 10px;
                        }
                        div{
                            a{
                                font-size: 13px;
                                color: #adadad;
                                &:hover, &:active{
                                    color: #d8d8d8;
                                }
                            }
                            .d-flex{
                                .sidebarFooterTitle{
                                    font-size: 13px;
                                    padding-left: 10px;
                                }
                                &.active{
                                    .sidebarFooterIcon{
                                        position: relative;
                                        &::after{
                                            content: "";
                                            position: absolute;
                                            bottom: -8px;
                                            left: -3px;
                                            width: 20px;
                                            height: 2px;
                                            background-color: $primary_color;
                                        } 
                                    }
                                }
                            }
                        }
                    }
                    &.collapsed{
                        &::before{
                            height: 0;
                        }
                        .sidebarFooterFlex{
                            transform: translateX(-100%);
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// Mobile responsive

@media(max-width: 1060px){
    // Layout
    .mainApp{
        .sidebarAside{
            width: 0px;
            .sidebar{
                transform: translateX(-200%);
            }
        }
        .mainPages{
            width: calc(100% - 0px);
        }
        &.collapsed{
            .sidebarAside{
                max-width: 0px;
                min-width: 0px;
            }
        }
        &.menuActive{
            .sidebarAside{
                .sidebar{
                    transform: translateX(0%);
                }
            }
        }
    }
}