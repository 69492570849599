// QuestionsCreation
.questionsCreation {
    .questionBox {
        .questionBoxChild {
            background: $color_white;
            border: 2px solid transparent;
            box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
            border-radius: 5px;

            .questionBoxChildLeft {
                span{
                    font-weight: $fontSemiBold
                }
            }

            .questionBoxChildRight {
                .questionFormGroup {
                    textarea {
                        @include input_style;
                        height: 80px;
                        resize: none;
                        font-weight: $fontRegular;
                    }
                }

                .questionsOptionsCreation {
                    .questionsOptionsCreationChild {
                        width: 100%;
                        max-width: 400px;

                        .optionLabelCreation {
                            input {
                                padding: 0 10px;
                                border: 1px solid transparent;
                                width: 100%;
                                font-weight: $fontSemiBold;
                                font-size: 14px;
                                color: #000000;

                                &::placeholder {
                                    font-weight: $fontMedium;
                                    font-style: italic;
                                }

                                &:active,
                                &:focus {
                                    border-color: #EBEBEB
                                }
                            }
                        }

                        .formGroup {
                            .d-flex {
                                @include input_style;
                                padding: 0;
                            }

                            input {
                                padding: 10px;
                                padding-right: 0;
                                border: none;
                                font-weight: $fontMedium;
                                &::placeholder {
                                    font-style: italic;
                                }
                            }

                            button {
                                border: none;
                                padding: 10px 12px;
                                background-color: transparent;
                                svg{
                                    width: 13px;
                                    height: auto;
                                }
                            }
                        }

                        .addOptionAction {
                            border: none;
                            background-color: transparent;
                            width: 100%;
                            max-width: fit-content;
                            margin: 0 auto;
                        }
                    }
                }
            }

            .qestionAddOnOptions{
                button{
                    background-color: transparent;
                    border: none;
                    svg{
                        path{
                            fill: #CACACA;
                            transition: .2s;
                        }
                        &:hover{
                            path{
                                fill: $primary_color;
                            }
                        }
                    }
                }
            }
        }
    }
    .addquestionAction{
        .customButton{
            button{
                width: 100%;
                max-width: fit-content;
                margin: 0 auto;
            }
        }
    }
}