// projectViewSection
.projectViewSection {
    .customButton.noBg {
        button {
            box-shadow: none;
        }
    }

    .PVDetails {
        background: #FFFFFF;
        box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        .PVBox {
            $CH: 70px;
            width: $CH;
            height: $CH;
            background: #79D8C7;
            @include flex_center;
            border-radius: 5px;
            font-size: 1.5rem;
            color: $color_white;
            font-weight: $fontSemiBold;
        }

        .PVDetailCon {
            .subtitle-text {
                input {
                    border: 1px solid transparent;
                    font-weight: $fontSemiBold;

                    &:active,
                    &:focus,
                    &:hover {
                        border-color: #000;
                    }

                    &::placeholder {
                        font-weight: $fontRegular;
                        // font-size: 1.5rem;
                        font-style: italic;
                    }
                }
            }

            .p-text {
                font-weight: $fontRegular;

                input {
                    border: 1px solid transparent;
                    font-weight: $fontSemiBold;

                    &:active,
                    &:focus,
                    &:hover {
                        border-color: #000;
                    }

                    &::placeholder {
                        font-weight: $fontRegular;
                        // font-size: 1.5rem;
                        font-style: italic;
                    }
                }
            }
        }
    }

    .PVUsers {
        .PVUserTitle {
            font-size: 12px;
            font-weight: $fontSemiBold;
        }

        .PVUsersRow {
            .PVUsersChild {
                position: relative;
                border: 0.5px solid #D5D5D5;
                border-radius: 50px;

                span {
                    position: relative;
                    $CH: 25px;
                    width: $CH;
                    height: $CH;
                    display: block;
                    background-color: #79D8C7;
                    border-radius: 50%;
                    @include flex_center;
                    color: $color_white;
                    font-size: 10px;
                    font-weight: $fontSemiBold;

                    svg {
                        position: absolute;
                        width: 20px;
                        height: auto;
                        top: -11px;
                        left: -5px;
                    }
                }

                .PVUserName {
                    color: #4E4E4E;
                    font-size: 12px;
                    font-weight: $fontSemiBold;
                    input{
                        color: #ffffff;
                        font-size: 12px;
                        font-weight: $fontSemiBold;
                        background-color: transparent;
                        border: none;
                        &::placeholder{
                            color: #ffffff;
                            opacity: 1;
                        }
                    }
                }

                .PVRemoveUser {
                    background-color: transparent;
                    border: none;
                    position: absolute;
                    width: 25px;
                    height: auto;
                    right: 3px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.addAdminButton {
                    background-color: $primary_color;

                    span {
                        all: unset;
                        display: flex;

                        svg {
                            all: unset;
                            position: relative;
                            $CH: 20px;
                            width: $CH;
                            height: $CH;

                            path {
                                fill: $color_white;
                            }
                        }
                    }

                    .PVUserName {
                        color: $color_white;
                    }
                }
            }
        }
    }

    .PVDDetails {
        background: #FFFFFF;
        box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        h1.subtitle-text {
            font-size: .8rem;
            font-weight: $fontSemiBold;
        }

        .PVDDetailsList {
            background: #FFFFFF;
            box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
            border-radius: 5px;

            h5.subtitle-text {
                font-size: 1rem;
                font-weight: $fontSemiBold;
                input {
                    border: 1px solid transparent;
                    font-weight: $fontSemiBold;

                    &:active,
                    &:focus,
                    &:hover {
                        border-color: #000;
                    }

                    &::placeholder {
                        font-weight: $fontRegular;
                        font-style: italic;
                    }
                }
            }

            .p-text {
                font-weight: $fontRegular;
                font-size: 12px;
                width: 100%;
                max-width: 600px;
                color: $color_black;
                input {
                    border: 1px solid transparent;
                    font-weight: $fontSemiBold;

                    &:active,
                    &:focus,
                    &:hover {
                        border-color: #000;
                    }

                    &::placeholder {
                        font-weight: $fontRegular;
                        // font-size: 1.5rem;
                        font-style: italic;
                    }
                }
            }

            button {
                background-color: transparent;
                border: none;
            }

            .PVDUsers {
                display: none;
                border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
            }

            &.active {
                .PVDUsers {
                    display: block;
                }
            }
        }
    }
}