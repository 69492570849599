// projectListSection
.projectListSection {
    .assessmentTopSticky {
        position: sticky;
        top: $topNavHeight;
        background-color: $color_white;
        z-index: 1;
    }

    .customButton.noBg {
        button {
            box-shadow: none;
        }
    }

    .projectList {
        .projectListChild{
            background: #FFFFFF;
            box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            .PLBox{
                $CH: 70px;
                width: $CH;
                height: $CH;
                background: #79D8C7;
                @include flex_center;
                border-radius: 5px;
                font-size: 1.5rem;
                color: $color_white;
                font-weight: $fontSemiBold;
            }
            .PLDetails{
                .p-text{
                    font-weight: $fontRegular;
                }
            }
            button{
                border: none;
                background-color: transparent;
                svg{
                    path{
                        fill: $primary_color;
                    }
                }
            }
            .PLActions{
                .lineLeft{
                    border-left: 1px solid rgba($color: #000000, $alpha: 0.1);
                    button{
                        &.disabled{
                            svg{
                                path{
                                    fill: $disbled_color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}